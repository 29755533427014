
h3.card-title {
  font-size: 1.41rem;
  padding-top: $spacer * 0.5;;
  margin-bottom: 0;
  float: left;
}

.dropdown-item .fa {
  margin-right: $spacer * 0.5;
}

.btn-secondary {
  color: white;
}

.card {
  table {

    margin-bottom: 0;

    tr:first-child td, th {
      border-top: 0px;
    }
  }
}

.table {

  thead th {
    vertical-align: bottom;
    border-bottom: (1 * $table-border-width) solid;

    @include themes($table-theme-map, $create: parent) {
      border-bottom-color: themes-get-value("table-border-color");
    }
  }
}

#toast-container > .toast {
  background-image: none !important;
}

#toast-container > .toast:before {
  position: relative;
  font-family: "Font Awesome 5 Pro";
  font-size: 24px;
  line-height: 18px;
  float: left;
  margin-left: -1em;
  color: #FFF;
  padding-right: 0.5em;
  margin-right: 0.5em;
}

#toast-container .toast-warning:before {
  content: "\f05a";
}

#toast-container .toast-error:before {
  content: "\f05e";
}

#toast-container .toast-info:before {
  content: "\f05a";
}

#toast-container .toast-success:before {
  content: "\f058";
}

.accordion .card {
  margin-bottom: 0;
}

[data-toggle="collapse"] .fa:before {
  content: "\f138";
}

[data-toggle="collapse"].collapsed .fa:before {
  content: "\f13a";
}

div.table-responsive {
  min-height: 400px;
}

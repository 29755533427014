
/*
TODO: more distinction between this file and common.css
*/

/* Colors
--------------------------------------------------------------------------------------------------*/

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content, /* for gutter border */
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  @include themes($border-theme-map) {
    border-color: themes-get-value("border-color");
  }
}

.fc-unthemed .fc-popover {
  background-color: #fff;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header,
.fc-unthemed .fc-list-heading td {
  background: #eee;
}

.fc-unthemed td.fc-today {
  background: #fcf8e3;
}

.fc-unthemed .fc-disabled-day {
  background: #d7d7d7;
  opacity: .3;
}


/* Icons
--------------------------------------------------------------------------------------------------
from https://feathericons.com/ and built with IcoMoon
*/

@import './icons/icons';

.fc-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  text-align: center;
}


/* Buttons
--------------------------------------------------------------------------------------------------
Lots taken from Flatly (MIT): https://bootswatch.com/4/flatly/bootstrap.css
*/

/* reset */

.fc-button {
  border-radius: 0;
  overflow: visible;
  text-transform: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.fc-button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

.fc-button {
  -webkit-appearance: button;
}

.fc-button:not(:disabled) {
  cursor: pointer;
}

.fc-button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

/* theme */

.fc-button {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.4em 0.65em;
  font-size: 1em;
  line-height: 1.5;
  border-radius: 0.25em;
}

.fc-button:hover {
  color: #212529;
  text-decoration: none;
}

.fc-button:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25);
}

.fc-button:disabled {
  opacity: 0.65;
}

/* "primary" coloring */

.fc-button-primary {
  color: #fff;
  background-color: #2C3E50;
  border-color: #2C3E50;
}

.fc-button-primary:hover {
  color: #fff;
  background-color: #1e2b37;
  border-color: #1a252f;
}

.fc-button-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 91, 106, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(76, 91, 106, 0.5);
}

.fc-button-primary:disabled {
  color: #fff;
  background-color: #2C3E50;
  border-color: #2C3E50;
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
  color: #fff;
  background-color: #1a252f;
  border-color: #151e27;
}

.fc-button-primary:not(:disabled):active:focus,
.fc-button-primary:not(:disabled).fc-button-active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 91, 106, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(76, 91, 106, 0.5);
}

/* icons within buttons */

.fc-button .fc-icon {
  vertical-align: middle;
  font-size: 1.5em; // bump up the size (but don't make it bigger than line-height of button, which is 1.5em also)
}


/* Buttons Groups
--------------------------------------------------------------------------------------------------*/

.fc-button-group {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.fc-button-group > .fc-button {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.fc-button-group > .fc-button:hover {
  z-index: 1;
}

.fc-button-group > .fc-button:focus,
.fc-button-group > .fc-button:active,
.fc-button-group > .fc-button.fc-button-active {
  z-index: 1;
}

.fc-button-group > .fc-button:not(:first-child) {
  margin-left: -1px;
}

.fc-button-group > .fc-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fc-button-group > .fc-button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}



/* Popover
--------------------------------------------------------------------------------------------------*/

.fc-unthemed .fc-popover {
  border-width: 1px;
  border-style: solid;
}


/* List View
--------------------------------------------------------------------------------------------------*/

.fc-unthemed .fc-list-item:hover td {
  background-color: #f5f5f5;
}

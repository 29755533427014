.select2-container--coreui {
  .select2-selection--multiple {

    // height of input box
    min-height: $input-height !important;

    // input box
    .select2-selection__rendered {
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      padding: 0 5px;
      width: 100%;

      .select2-search__field {
        @include themes($c-multi-select-theme-map) {
          color: themes-get-value("c-multi-select-search-color");
          background-color: themes-get-value("c-multi-select-search-bg");
        }
      }
    }

    // each selected item in input box
    .select2-selection__choice {
      border: 1px solid;
      border-radius: $btn-border-radius-sm;
      padding: 0;
      padding-right: 5px;
      cursor: pointer;
      float: left;
      margin-top: 0.3em;
      margin-right: 5px;
      @include themes($form-theme-map) {
        color: themes-get-value("input-color");
      }
      @include theme-get-color("info") {
        border-color: $color
      }
    }

    // x button of each selected item in input box
    .select2-selection__choice__remove {
      // color: darken($light, 20%);
      width: 26px;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      text-align: center;
      margin-right: .375rem;
      border-right: 1px solid;
      background: #fff;
      @include theme-get-color("info") {
        color: lighten($color, 20);
        border-color: $color;
        background: rgba($color,.0);
      }

      &:hover {
        @include theme-get-color("info") {
          color: $color;
          background: rgba($color,.1);
        }
      }
    }
  }
}
